import FadeIn, { FromDirection } from '@animations/FadeIn';
import TV from '@animations/TV';
import {
  Box,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  firstName: string;
  lastName: string;
  biography: string;
  avatar?:
    | IGatsbyImageData
    | GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>;
};

const ProfileSection: FC<Props> = ({
  firstName,
  lastName,
  biography,
  avatar,
}) => {
  const [ref, inView] = useInView({ threshold: 0.5 });
  const image = getImage(avatar as IGatsbyImageData);

  return (
    <Box as='section' h='100vh' ref={ref}>
      <Center h='100%'>
        <Container>
          <SimpleGrid minChildWidth='400px' spacing='1.5rem'>
            <TV inView={inView}>
              {image && (
                <GatsbyImage image={image} alt={`${firstName} ${lastName}`} />
              )}
            </TV>
            <Box>
              <FadeIn from={FromDirection.Bottom} inView={inView}>
                <Text>{biography}</Text>
              </FadeIn>
              <FadeIn from={FromDirection.Bottom} inView={inView}>
                <Heading>{`${firstName} ${lastName}`}</Heading>
              </FadeIn>
            </Box>
          </SimpleGrid>
        </Container>
      </Center>
    </Box>
  );
};

export default ProfileSection;
