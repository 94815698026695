const variants = {
  on: {
    clipPath: [
      'inset(50% 50% 50% 50%)',
      'inset(45% 45% 45% 45%)',
      'inset(48% 0% 48% 0%)',
      'inset(0% 0% 0% 0%)',
    ],
  },
  off: {
    clipPath: [
      'inset(0% 0% 0% 0%)',
      'inset(45% 0% 45% 0%)',
      'inset(45% 45% 45% 45%)',
      'inset(50% 50% 50% 50%)',
    ],
  },
  initial: {
    clipPath: 'inset(0%)',
  },
};

export default variants;
