import { Box, Center, Container, Heading, Text } from '@chakra-ui/react';
import ProfileSection from '@components/ProfileSection';
import { SEO } from '@feature/seo';
import { graphql, PageProps } from 'gatsby';
import { FC } from 'react';

export const Head = () => <SEO title='About' />;

const AboutPage: FC<PageProps<GatsbyTypes.AboutPageQueryQuery>> = ({
  data,
}) => {
  if (!data.twill.aboutPage) {
    throw new Error('Data for about page not found');
  }

  return (
    <>
      <Box as='section' h='100vh'>
        <Center h='100%'>
          <Container>
            <Heading as='h1'>Who are we</Heading>
            <Heading mb='4'>
              {
                data.twill.aboutPage.translations?.find(
                  (x) => x?.locale === 'en'
                )?.headline
              }
            </Heading>

            <Text fontSize='xl'>
              {
                data.twill.aboutPage.translations?.find(
                  (x) => x?.locale === 'en'
                )?.description
              }
            </Text>

            <Text fontSize='xl'>
              What we do Photography Videography Music videos Graphic design
              Logos Designed advertisements Covers
            </Text>
          </Container>
        </Center>
      </Box>

      <Heading as='h1' mb='4' textAlign='center'>
        Team
      </Heading>

      {data.twill.aboutPage.people?.map(
        (person) =>
          person && (
            <ProfileSection
              key={person.id}
              firstName={person.first_name}
              lastName={person.last_name}
              biography={
                person.translations.find((x) => x.locale === 'en')
                  ?.biography as string
              }
              avatar={
                person.avatar.default_url_sharp?.childImageSharp
                  ?.gatsbyImageData
              }
            />
          )
      )}
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    twill {
      aboutPage {
        translations {
          locale
          headline
          description
        }
        people {
          id
          first_name
          last_name
          avatar {
            default_url
            default_url_sharp {
              childImageSharp {
                gatsbyImageData(width: 700, layout: CONSTRAINED)
              }
            }
          }
          translations {
            locale
            biography
          }
        }
      }
    }
  }
`;
