import { motion } from 'framer-motion';
import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';
import variants from './Variants';

/**
 * A TV like animation when its turned on
 *
 */

type Props = {
  inView?: boolean;
  restart?: boolean;
};

enum AnimationState {
  On = 'on',
  Off = 'off',
}

// CSS-Version to hide
const TV: FC<PropsWithChildren<Props>> = ({ children, inView, restart }) => {
  const [animation, setAnimation] = useState(AnimationState.Off);

  useLayoutEffect(() => {
    if (inView) {
      setAnimation(AnimationState.On);
    } else if (restart) {
      setAnimation(AnimationState.Off);
    }
  }, [inView, restart]);

  return (
    <motion.div
      variants={variants}
      animate={animation}
      initial='initial'
      transition={{
        duration: 0.5,
        ease: 'circIn',
      }}
    >
      {children}
    </motion.div>
  );
};

TV.defaultProps = {
  inView: false,
  restart: false,
};

export default TV;
